<template>
  <v-container>
    <v-row class="pt-5 pb-5">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn elevated color="primary" class="mr-2" @click="initData"><v-icon>mdi-refresh</v-icon> Refresh</v-btn>
        <StartTranslationJob @createdTranslationJob="initData" />
      </v-col>
    </v-row>
    <div v-if="jobs.length > 0 && !loading">
      <v-data-table :headers="headers" :items="jobs" item-key="JobId" class="elevation-1 pa-5">  
        <template slot="item.submitted" slot-scope="props">
            {{ formatDate(props.item.SubmittedTime) }}
        </template>        
        <template slot="item.ended" slot-scope="props">
            <span v-if="props.item.JobStatus == 'COMPLETED'">{{ formatDate(props.item.EndTime) }}</span>
        </template>
        <template slot="item.actions" slot-scope="props">
          <v-btn color="primary" fab small class="ma-2" title="View Details" @click="showJobDetailsOverlay(props.item)">
            <v-icon>mdi-file-document-multiple</v-icon>
          </v-btn>
          <v-btn color="secondary" fab small class="ma-2" title="Download Files" :disabled="props.item.JobStatus !== 'COMPLETED'" @click="showFilesForDownload(props.item)">
            <v-icon>mdi-file-download</v-icon>
          </v-btn>
          <!--<v-btn color="error" fab small class="ma-2" title="Stop Translate Job" :disabled="props.item.JobStatus == 'COMPLETED'">
            <v-icon>mdi-alert-octagon</v-icon>
          </v-btn>-->
        </template>
      </v-data-table>
    </div>
    <div class="mt-5" v-if="loading">
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </div>
    <div class="mt-5" v-if="jobs.length == 0 && !loading">
      <v-card class="pa-3 text-center">
        You have not created a translation job yet. Click below to start one. 
      </v-card>
    </div>
    <JobDetails :showJobDetails="showJobDetails" :job="selectedJobDetails" @closeJobDetails="closeJobDetailsOverlay" />
    <DownloadFiles :showDownloadFiles="showDownloadFiles" :downloadFiles="downloadFiles" @closeDownloadFiles="closeDownloadFiles" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import _orderBy from 'lodash/orderBy';
import formatDate from '@/plugins/formatDate';
import httpRequest from "@/plugins/httpService";
import DownloadFiles from "@/components/DownloadFiles";
import JobDetails from "@/components/JobDetails";
import StartTranslationJob from "@/components/StartTranslationJob";

export default {
  name: 'Batch',

  components: {
    DownloadFiles,
    JobDetails,
    StartTranslationJob
  },

  data: () => ({
    downloadFiles: {},
    headers: [
      { text: 'Name', value: 'JobName', sortable: true, width: '25%' },
      { text: 'Status', value: 'JobStatus', sortable: true, width: '15%' },
      { text: 'Submitted Time', value: 'submitted', sortable: true, width: '20%' },
      { text: 'End Time', value: 'ended', sortable: true, width: '20%' },
      { text: 'Documents', value: 'JobDetails.InputDocumentsCount', sortable: false, width: '8%' },
      { text: 'Actions', value: 'actions', sortable: false, width: '12%' }
    ],
    jobs: [],
    loading: false,
    startJob: false,
    selectedJobDetails: {},
    showDownloadFiles: false,
    showJobDetails: false
  }),

  computed: {
    ...mapGetters({
      user: "app/user"
    })
  },

  methods: {
    
    cleanupJobName(text){
      const username = this.user.name.replace(/[\W_]+/g, '_');
      return text.replace(username,'').split('_').join(' ');
    },

    closeDownloadFiles() {
      this.downloadFiles = {};
      this.showDownloadFiles = false;
    },

    closeJobDetailsOverlay() {
      this.showJobDetails = false;
    },

    formatDate(date) {
      return formatDate.formatWithTime(date);
    },

    async initData(){
      this.loading = true;
      const response = await httpRequest.listTransactionJobs();
      const unOrderedJobs = response.data.data.TextTranslationJobPropertiesList.map(m => {
        m.JobName = this.cleanupJobName(m.JobName)
        return m;
      });
      this.jobs = _orderBy(unOrderedJobs, 'JobName', 'asc');
      this.loading = false;
    },
    
    showJobDetailsOverlay(job){
      this.selectedJobDetails = job;
      this.showJobDetails = true;
    },

    async showFilesForDownload(job){
      const response = await httpRequest.listTranslatedFiles(job.OutputDataConfig.S3Uri);
      this.downloadFiles = response.data.data;
      console.log(this.downloadFiles);
      this.showDownloadFiles = true;
    }
  
  },
  
  created() {
    this.initData();
  }

}

</script>
