<template>
  <v-row class="d-flex justify-center align-center" style="padding-top: 15%;">
    <v-col cols="12" lg="3" xl="3">
      <router-link :to="{name: 'translator'}" style="text-decoration: none;">
        <v-card height="100%" class="ma-2 text-center" elevation="5">
          <v-icon :size="75" class="pt-2" color="secondary">mdi-comment-text</v-icon>
          <v-card-title class="justify-center">Text Translate</v-card-title>
          <v-card-subtitle class="text-wrap">Convert blocks of text and export to a CSV</v-card-subtitle>
        </v-card>
      </router-link>
    </v-col>
    <v-col cols="12" lg="3" xl="3">
      <router-link :to="{name: 'batch'}" style="text-decoration: none;">
        <v-card height="100%" class="ma-2 text-center" elevation="5">
          <v-icon :size="75" class="pt-2" color="secondary">mdi-file-multiple</v-icon>
          <v-card-title class="justify-center">Batch File Translate</v-card-title>
          <v-card-subtitle class="text-wrap">Convert sets of files and download translated documents</v-card-subtitle>
        </v-card>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'Dashboard',

  components: {},

  data: () => ({}),

  methods: {}

}

</script>
