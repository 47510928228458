<template>
  <div class="app-container">
    <v-app-bar app color="primary" dark>
      <router-link :to="{name: 'dashboard'}">
        <v-img src="@/assets/logo-home.png" position="left center" contain height="64px" />
      </router-link>
      <v-spacer></v-spacer>
      <h4>Text Translator</h4>
      <v-menu offset-y v-if="authenticated">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="ml-5">
            <v-icon dark>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{user.name}}</v-list-item-title>
                <v-list-item-subtitle>User</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text @click="logout">Logout</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
    <div class="main-body">
      
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "app",
  components: {},

  data() {
    return {};
  },
  
  computed: {   
    ...mapGetters({
      authenticated: "app/authenticated",
      user: "app/user"
    })
  },

  methods: {
    ...mapActions({
      setAuthenticated: "app/setAuthenticated",
      setJwt: "app/setJwt",
      setUser: "app/setUser"
    }),
    
    async logout() {
      this.setAuthenticated(false);
      this.setJwt(null);
      this.setUser({});
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
};
</script>
<style scoped>

  .main-body {
    margin-top: 64px; 
  }

</style>
