<template> 
  <div>
    <v-dialog v-model="showJobDetails"  width="600">
      <v-card tile>
        <v-card-title class="text-h5 primary lighten-2">
          Details
        </v-card-title>
        
        <v-list two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Name</v-list-item-title>
              <v-list-item-subtitle>{{job.JobName}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Status</v-list-item-title>
              <v-list-item-subtitle>{{job.JobStatus}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Submitted Time</v-list-item-title>
              <v-list-item-subtitle>{{formatDate(job.SubmittedTime)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="job.JobStatus == 'COMPLETED'">
            <v-list-item-content>
              <v-list-item-title>End Time</v-list-item-title>
              <v-list-item-subtitle>{{formatDate(job.EndTime)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Translation</v-list-item-title>
              <v-list-item-subtitle>{{getLanguageCodeDesc(job.SourceLanguageCode)}} to {{getLanguageCodeDesc(job.TargetLanguageCodes[0])}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="job.JobDetails">
            <v-list-item-content>
              <v-list-item-title>Details</v-list-item-title>
              <v-list-item-subtitle class="pt-2">
                  <span class="mr-2">Total</span>
                  <v-badge class="mr-2" color="primary" :content="job.JobDetails.InputDocumentsCount" v-if="job.JobDetails.InputDocumentsCount" />
                  <span class="ml-5 mr-2" v-if="job.JobDetails.TranslatedDocumentsCount">Translated</span> 
                  <v-badge class="mr-2" color="green" :content="job.JobDetails.TranslatedDocumentsCount" v-if="job.JobDetails.TranslatedDocumentsCount" />
                  <span class="ml-5 mr-2" v-if="job.JobDetails.DocumentsWithErrorsCount">Errors</span> 
                  <v-badge class="mr-2" color="error" :content="job.JobDetails.DocumentsWithErrorsCount" v-if="job.JobDetails.DocumentsWithErrorsCount" />
                </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Message / Notes</v-list-item-title>
              <v-list-item-subtitle>{{job.Message}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn elevated @click="closeJobDetails" class="ml-2"><v-icon>mdi-close</v-icon> Close</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as axios from "axios";
import formatDate from '@/plugins/formatDate';

export default {
  name: 'JobDetails',
  components: {},
  props: {
    showJobDetails: Boolean,
    job: Object
  },
  data: () => ({
    codes: []
  }),
  methods: {

    closeJobDetails() {
      this.$emit('closeJobDetails');
    },
    
    formatDate(date) {
      return formatDate.formatFromNow(date);
    },

    async getLanguageCodes() {

      const localRequest = axios.create();
      const response = await localRequest.get('/language-codes.json');
      this.codes = response.data;
    },

    getLanguageCodeDesc(code){
      if (!code){ return ''; }
      const lang = this.codes.filter(f => { return f.Code == code });
      if (lang.length == 0){ return ''; }
      return lang[0].Language;
    }

  },

  created() {
    this.getLanguageCodes();
  }
};
</script>
