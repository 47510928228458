import * as axios from "axios";
import store from '@/store';

const httpRequest = axios.create({ headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
  } 
});

httpRequest.interceptors.request.use(function (config) {
  const token = store.getters['app/jwt'];
  if (token){ config.headers = { 'X-Auth-Token': `${token}`}; }
  return config;
});

httpRequest.interceptors.response.use(
  res => res,
  err => {
    if (err && err.response && err.response.status === 401) {
      store.commit('SET_AUTHENTICATED', false);
      store.commit('SET_JWT', null);
      store.commit('SET_USER', {});
      window.location.replace(process.env.VUE_APP_HOME_URL);
    }
  }
);

const checkUserAccess = async function(){
  const response = await httpRequest.post(process.env.VUE_APP_API_URL + 'user-portal-api', {
    commandType: 'userApplications'
  });
  const appGuid = process.env.VUE_APP_ACCESS_GUID;
  if (response && response.data && response.data.data.length > 0){
    return (response.data.data.filter(d => { return d.applicationguid == appGuid }).length > 0);
  }
  return false;
}

const getSignedUrlForDownload = async function(Key){
  const response = await httpRequest.post(process.env.VUE_APP_API_URL + 'pb-translate', {
    commandType: 'getSignedUrlForDownload',
    Key: Key
  });
  return response;
}

const getSignedUrlForUpload = async function(file, url){
  const response = await httpRequest.post(process.env.VUE_APP_API_URL + 'pb-translate', {
    commandType: 'getSignedUrlForUpload',
    filePath: file.name,
    contentType: file.type,
    url: url
  });
  return response;
}

const getUser = async function(){
  
  let postBody = {
    commandType: ''
  };

  const response = await httpRequest.post(process.env.VUE_APP_API_URL + 'user-portal-api', postBody);
  return response;
}
  
const listTransactionJobs = async function(){
  const response = await httpRequest.post(process.env.VUE_APP_API_URL + 'pb-translate', {
    commandType: 'listTransactionJobs'
  });
  return response;
}

const listTranslatedFiles = async function(s3uri){
  const response = await httpRequest.post(process.env.VUE_APP_API_URL + 'pb-translate', {
    commandType: 'listTranslatedFiles',
    s3uri: s3uri
  });
  return response;
}

const startTranslateJob = async function(jobName, url, sourceCode, targetCode, contentType){
  const response = await httpRequest.post(process.env.VUE_APP_API_URL + 'pb-translate', {
    commandType: 'startTextTranslationJob',
    JobName: jobName,
    url: url,
    SourceLanguageCode: sourceCode,
    TargetLanguageCode: targetCode,
    ContentType: contentType
  });
  return response;
}

const textTranslate = async function(source, sourceCode, targetCode){
  const response = await httpRequest.post(process.env.VUE_APP_API_URL + 'pb-translate', {
    commandType: 'textTranslate',
    textToTranslate: source,
    SourceLanguageCode: sourceCode,
    TargetLanguageCode: targetCode
  });
  return response;
}

export default {
  checkUserAccess,
  getSignedUrlForDownload,
  getSignedUrlForUpload,
  getUser,
  listTransactionJobs,
  listTranslatedFiles,
  startTranslateJob,
  textTranslate
};