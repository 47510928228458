<template> 
  <div>
    <v-btn elevated color="secondary" @click="start"><v-icon>mdi-sync</v-icon> Start Translation Job</v-btn>
    <v-dialog v-model="startJob" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-form id="questionnaire-form" ref="form" v-model="validForm">
          <v-row wrap class="pa-5 pt-10">
            <v-col cols="12" class="pt-0 pb-0">
              <v-text-field outlined v-model="formData.jobName" label="Job Name" required :rules="[v => !!v || 'Job Name is required']"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select outlined v-model="formData.sourceCode" :items="codes" item-text="Language" item-value="Code" label="Source Language" return-object></v-select>  
            </v-col>
            <v-col cols="12" md="6">
              <v-select outlined v-model="formData.targetCode" :items="codes" item-text="Language" item-value="Code" label="Target Language" return-object></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select outlined v-model="formData.contentType" :items="contentTypes" item-text="name" item-value="code" label="Content Type" return-object></v-select>  
               <small>*Remember, AWS Translate does not support PDF translation. File must be converted first before uploading. Also, jobs can only be submitted with like content type, so different file types require separate translate jobs.</small>  
            </v-col>
          </v-row>
          <v-row wrap class="pa-5 pt-1">
            <v-col cols="12" class="pt-0 pb-0">
              <Dropzone :uniqueId="uniqueId" ref="dropzone"></Dropzone>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="pa-5">  
          <v-col cols="12" class="d-flex justify-end">
            <v-btn elevated color="secondary" @click="startTranslate" :disabled="!validForm || !formData.sourceCode.Code || !formData.contentType.code"><v-icon>mdi-sync</v-icon> Start Job</v-btn>
            <v-btn elevated color="primary" @click="startJob = false" class="ml-2"><v-icon>mdi-content-paste</v-icon> Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  
  </div>
</template>

<script>
import * as axios from "axios";
import { mapActions, mapGetters } from "vuex";
import Dropzone from '@/components/Dropzone';
import httpRequest from "@/plugins/httpService";

export default {
  name: 'StartTranslationJob',
  components: {
    Dropzone
  },
  props: {},
  data: () => ({
    codes: [],
    contentTypes: [
      { name: 'HTML', code: 'text/html' },
      { name: 'Plain Text', code: 'text/plain' },
      { name: 'Word Document', code: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
      { name: 'PowerPoint', code: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
      { name: 'Excel', code: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      { name: 'XML', code: 'application/x-xliff+xml' }
    ],
    formData: {
      jobName: '',
      sourceCode: {
        Language: 'Spanish',
        Code: 'es'
      },
      targetCode: {
        Language: 'English',
        Code: 'en'
      },
      contentType: {},
      files: []
    },
    startJob: false,
    uniqueId: '',
    validForm: false
  }),
  
  computed: {   
    ...mapGetters({
      user: "app/user"
    })
  },

  methods: {
    
    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage",
      addErrorMessage: "app/addErrorMessage",
    }),

    defaultFormData() {
      this.formData = {
        jobName: '',
        sourceCode: {
          Language: 'Spanish',
          Code: 'es'
        },
        targetCode: {
          Language: 'English',
          Code: 'en'
        },
        contentType: {},
        files: []
      }
    },
    
    async getLanguageCodes() {

      const localRequest = axios.create();
      const response = await localRequest.get('/language-codes.json');
      this.codes = response.data.filter(f => { return f.Code != 'auto' });
    },

    start() {
      this.startJob = true;
      this.uniqueId = Math.floor(Math.random() * Date.now());
    },

    async startTranslate() {
      const response = await httpRequest.startTranslateJob(this.formData.jobName, this.uniqueId, this.formData.sourceCode.Code, this.formData.targetCode.Code, this.formData.contentType.code);
      if (response.data && response.data.data.JobStatus == 'SUBMITTED'){
        this.addSuccessMessage('Successfully created translation job!');
        this.$emit('createdTranslationJob');
        this.defaultFormData();
        this.$refs.dropzone.clearDropzone();
        this.startJob = false;
        
      } else {
        this.addErrorMessage('Unable to create translation job!');
        //this.startJob = false;
      }
    }

  },

  created() {
    this.getLanguageCodes();
  }
};
</script>
