<template> 
  <div>
    <v-dialog v-model="showDownloadFiles"  width="600">
      <v-card tile v-if="downloadFiles.Contents">
        <v-card-title class="text-h5 primary lighten-2">
          Files
        </v-card-title>
        
        <div class="pa-3 text-center" v-if="downloadFiles.Contents.length == 0">
          This job has no files to download.
        </div>
        
        <v-list two-line v-if="downloadFiles.Contents.length > 0">
          <v-list-item v-for="(file,index) in downloadFiles.Contents" :key="index">
            <v-list-item-content>
              <v-list-item-title v-text="getFileName(file.Key)"></v-list-item-title>
              <v-list-item-subtitle>{{file.Size}} kb</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn fab color="primary" @click="getDownloadUrl(file)">
                <v-icon>mdi-file-download</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn elevated @click="closeDownloadFiles" class="ml-2"><v-icon>mdi-close</v-icon> Close</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      
    </v-dialog>
  </div>
</template>

<script>
import * as axios from "axios";
import httpRequest from "@/plugins/httpService";

export default {
  name: 'DownloadFiles',
  components: {},
  props: {
    showDownloadFiles: Boolean,
    downloadFiles: Object
  },
  data: () => ({}),
  methods: {

    closeDownloadFiles() {
      this.$emit('closeDownloadFiles');
    },

    async downloadItem (url, label) {
      const localRequest = axios.create();
      const response = await localRequest.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    async getDownloadUrl(file){
      const response = await httpRequest.getSignedUrlForDownload(file.Key);
      this.downloadItem(response.data.data, this.getFileName(file.Key));
    },

    getFileName(file){
      return file.replace(this.downloadFiles.Prefix, '');
    }
  
  }

};
</script>
