<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <v-select outlined v-model="translate.sourceCode" :items="codes" item-text="Language" item-value="Code" label="Source Language" return-object></v-select>  
      </v-col>
      <v-col cols="12" md="6" class=" d-flex align-stretch">
        <v-select outlined v-model="translate.targetCode" :items="codes" item-text="Language" item-value="Code" label="Target Language" return-object></v-select>
      </v-col>
      <v-col cols="12" md="6"> 
        <v-textarea outlined name="Source Text" label="Source Text" v-model="translate.source" />
      </v-col>
      <v-col cols="12" md="6">   
        <v-skeleton-loader type="article" v-if="loading"></v-skeleton-loader>
        <v-card v-if="!loading" style="min-height: 150px;">
          <v-card-title>Translation</v-card-title>
          <v-card-text>{{translate.result}}</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn elevated color="secondary" :disabled="!translate.source" @click="translateText"><v-icon>mdi-sync</v-icon> Translate</v-btn>
        <v-btn elevated color="primary" :disabled="!translate.result" @click="copyToClipboard(translate.result)" class="ml-2"><v-icon>mdi-content-paste</v-icon> Copy To Clipboard</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="translations.length > 0">
      <v-col cols="12">
        <v-data-table :headers="translationHeaders" :items="translations" :items-per-page="10" class="elevation-1">
          <template slot="item.action" slot-scope="props">
            <v-btn elevated color="primary" small @click="copyToClipboard(props.item.TranslatedText)" class="ml-2"><v-icon>mdi-content-paste</v-icon> Copy To Clipboard</v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <vue-json-to-csv :json-data="translations" csv-title="Text_Translations">
          <v-btn elevated color="primary" class="ml-2"><v-icon>mdi-download</v-icon> Download All Translations</v-btn>
        </vue-json-to-csv>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import * as axios from "axios";
import httpRequest from "@/plugins/httpService";
import VueJsonToCsv from 'vue-json-to-csv';

export default {
  name: 'Translation',

  components: {
    VueJsonToCsv
  },

  data: () => ({
    codes: [],
    loading: false,
    translate: {
      source: '',
      result: '',
      sourceCode: {
        Language: 'Default (Auto-Detect)',
        Code: 'auto'
      },
      targetCode: {
        Language: 'English',
        Code: 'en'
      }
    },
    translationHeaders: [{
      text: 'Source Language',
      value: 'SourceLanguage',
      sortable: false
    },{
      text: 'Target Language',
      value: 'TargetLanguage',
      sortable: false
    },{
      text: 'Source Text',
      value: 'SourceText',
      sortable: false
    },{
      text: 'Translated Text',
      value: 'TranslatedText',
      sortable: false
    },{
      text: 'Actions',
      value: 'action',
      sortable: false
    }],
    translations: []
  }),

  methods: {
    ...mapActions({
      addSuccessMessage: "app/addSuccessMessage",
      addErrorMessage: "app/addErrorMessage",
    }),

    copyToClipboard(text) {
      let parentThis = this;
      navigator.clipboard.writeText(text).then(function() {
        parentThis.addSuccessMessage('Successfully copied to clipboard');
      }, function() {
        parentThis.addErrorMessage('Error: could not copy text to clipboard');
      });
    },

    async getLanguageCodes() {

      const localRequest = axios.create();
      const response = await localRequest.get('/language-codes.json');
      this.codes = response.data;
    },

    async translateText() {

      if (this.translate.targetCode.Code != 'auto'){

      this.loading = true;
      const response = await httpRequest.textTranslate(this.translate.source, this.translate.sourceCode.Code, this.translate.targetCode.Code);
      this.translate.result = response.data.data.TranslatedText; 
      this.translations.unshift({
        SourceLanguage: this.codes.filter(f => { return f.Code == response.data.data.SourceLanguageCode })[0].Language, //this.translate.sourceCode.Language,
        TargetLanguage: this.codes.filter(f => { return f.Code == response.data.data.TargetLanguageCode })[0].Language, //this.translate.targetCode.Language,
        SourceText: this.translate.source,
        TranslatedText: this.translate.result
      });
      this.loading = false;   

      } else {
        this.addErrorMessage('Cannot translate to Auto-Detect');
      }

    },

    init() {
      this.loading = true;
      this.getLanguageCodes();
      this.loading = false;
    }
    
  },

  created() {
    this.init();
  }
}

</script>
