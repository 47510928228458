import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "@/auth/authGuard";

import Batch from '@/views/Batch.vue';
import Dashboard from '@/views/Dashboard.vue';
import Translation from '@/views/Translation.vue';
import Login from '@/Login.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: authGuard,
      component: Dashboard
  },
  {
      path: '/batch',
      name: 'batch',
      beforeEnter: authGuard,
      component: Batch
  },
  {
      path: '/translator',
      name: 'translator',
      beforeEnter: authGuard,
      component: Translation
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
